.advantages {
  margin-bottom: 70px;
  
  @media (max-width: 720.98px) {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 44px;
    text-align: center;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 33px;

    @media (max-width: 960.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 720.98px) {
      display: flex;
      flex-direction: column;
      grid-row: 24px;
    }
  }

  &-item {
    position: relative;
    padding: 78px 25px 30px 30px;
    border-radius: 15px;
    border: 1px solid #D7D7D7;

    &--big {
      padding: 0 19px 0 35px;
      grid-column: span 2;
      background: #EEF9FF;
      border: none;

      @media (max-width: 720.98px) {
        padding: 0 24px 30px;
      }

      @media (max-width: 480.98px) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: 480.98px) {
        display: block;
      }
    }

    &.hidden {
      display: none;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1250.98px) {
        padding-top: 30px;
        gap: 20px;
      }

      @media (max-width: 960.98px) {
        padding-top: 0;
      }

      @media (max-width: 720.98px) {
        flex-direction: column;
      }
    }

    &-left {
      max-width: 400px;

      @media (max-width: 1250.98px) {
        max-width: 280px;
      }

      @media (max-width: 720.98px) {
        order: 2;
      }

      .advantages-item__title {
        margin-bottom: 25px;
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;

        @media (max-width: 720.98px) {
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
        }
      }
    }

    &__icon {
      position: absolute;
      top: -14px;
      left: 25px;
      width: 82px;
      height: 82px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #EEF9FF;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: var(--color-main);
    }

    &__btn {
      position: relative;
      max-width: 320px;
      padding-right: 40px;
      transition: background .3s ease-in-out, color .3s ease-in-out;

      @media (max-width: 1250.98px) {
        max-height: 70px;
        text-align: center;
      }

      @media (max-width: 720.98px) {
        background: var(--color-light-blue);
        color: #fff;
      }

      &::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 20px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289Z' fill='%23102570'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V8H7C6.44772 8 6 7.55228 6 7Z' fill='%23102570'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        transition: background-image .3s ease-in-out;

        @media (max-width: 1250.98px) {
          top: 24px;
        }

        @media (max-width: 720.98px) {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V8H7C6.44772 8 6 7.55228 6 7Z' fill='white'/%3E%3C/svg%3E ");
        }
      }

      &:hover {
        background: var(--color-light-blue);
        color: #fff;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V8H7C6.44772 8 6 7.55228 6 7Z' fill='white'/%3E%3C/svg%3E ");
        }
      }
    }

    &-image {
      img {
        display: block;
        height: 100%;
      }
    }
  }

  &__show-more {
    display: none;

    @media (max-width: 480.98px) {
      width: 100%;
      display: block;
      margin: 20px 0 40px;
    }
  }
}
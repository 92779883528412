.footer {
  &-wrapper {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 720.98px) {
      flex-direction: column;
      gap: 15px;
      align-items: center;
    }
  }

  &__copyright {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #989898;

    @media (max-width: 480.98px) {
      max-width: 290px;
      text-align: center;
    }
  }

  &__privacy {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #989898;
  }
}
.btn {
  display: block;
  max-height: 56px;
  padding: 18.5px 50px 18.5px 25px;
  border: 1px solid var(--color-light-blue);
  border-radius: 35px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--color-main);
}
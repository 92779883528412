.nav {
  @media (max-width: 1250.98px) {
    display: none;
  }

  &.is-open {
    position: absolute;
    top: 150px;
    right: 0;
    width: 100%;
    display: block;
    padding: 40px 0 30px;
    background: #EEF9FF;
    border-radius: 15px;
    z-index: 6;

    @media (max-width: 960.98px) {
      top: 112px;
    }

    .nav-list {
      margin-bottom: 48px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }

  &-list {
    display: flex;

    &__btn {
      display: none!important;
      margin: 0 auto;

      @media (max-width: 1250.98px) {
        display: block!important;
      }
    }
  }

  &-item {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &__link {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-main);
      transition: color .3s ease-in-out;

      &:hover {
        color: var(--color-light-blue);
      }
    }
  }
}
.header {
  &-main {
    transition: all .3s ease-in-out;

    &.is-scrolling {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: #fff;
      box-shadow: 0px 19px 22.5px 0px #A1BECE40;
      z-index: 10;

      .header-logo {
        width: 72px;
        height: 72px;
      }

      .header-info__upper {
        display: none;
      }

      .header-info__mail {
        display: none;
      }
    }
  }

  &-wrapper {
    position: relative;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1250.98px) {
      justify-content: flex-start;
    }
  }

  &-logo {
    @media (max-width: 960.98px) {
      width: 72px;
      height: 72px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    @media (max-width: 1250.98px) {
      margin-left: auto;
    }

    &__upper {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #393939;
      text-align: right;

      @media (max-width: 720.98px) {
        font-size: 12px;
      }
    }

    &__phone {
      position: relative;
      padding-left: 40px;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      color: var(--color-main);

      @media (max-width: 720.98px) {
        padding-left: 21px;
        font-size: 16px;
        line-height: 19px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;

        @media (max-width: 720.98px) {
          top: 1px;
          width: 16px;
          height: 16px;
        }
      }
    }

    &__mail {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
      color: var(--color-light-blue);

      @media (max-width: 960.98px) {
        display: none;
      }
    }
  }

  &-burger {
    display: none;
    height: 25px;
    margin-left: 15px;
    cursor: pointer;

    @media (max-width: 1250.98px) {
      display: block;
    }

    &__line {
      position: relative;
      display: block;
      width: 35px;
      transform: translateY(8px);
      border-bottom: 3px solid var(--color-main);
      border-radius: 3px;
      transition: all .3s ease-in-out;

      @media (max-width: 720.98px) {
        transform: translateY(12px);
      }

      &.is-open {
        border: none;

        &::after {
          top: 2px;
          transform: rotate(45deg);
        }

        &::before {
          bottom: -5px;
          transform: rotate(-45deg);
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 8px;
        width: 100%;
        height: 3px;
        border-bottom: 3px solid var(--color-main);
        border-radius: 3px;
        transition: all .3s ease-in-out;
      }

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        width: 100%;
        height: 3px;
        border-bottom: 3px solid var(--color-main);
        border-radius: 3px;
        transition: all .3s ease-in-out;
      }
    }
  }
}
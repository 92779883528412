html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 400;

  &.scroll-disable {
    overflow-y: hidden;
  }
}

main {
  display: table-row;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: table;
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.container {
  max-width: var(--container-width);
  padding: 0 var(--container-offset);
  margin: 0 auto;

  @media (max-width: 1250.98px) {
    max-width: 960px;
  }

  @media (max-width: 960.98px) {
    max-width: 720px;
  }

  @media (max-width: 720.98px) {
    max-width: 540px;
  }

  @media (max-width: 480.98px) {
    max-width: none;
  }
}

.title {
  font-size: 40px;
  font-weight: 500;
  line-height: 47px;
  color: var(--color-main);

  @media (max-width: 720.98px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.form {
  &-container {
    max-width: 800px;
    margin: 0 auto;

    @media (max-width: 960.98px) {
      max-width: none;
      padding: 0 20px;
    }
  }

  &-wrapper {
    margin-bottom: 44px;
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 960.98px) {
      flex-direction: column;
    }
  }

  &-item {
    position: relative;
    width: 50%;

    @media (max-width: 960.98px) {
      width: 100%;
    }

    &--w100 {
      width: 100%;
    }

    &.success {
      .form__input {
        border: 1px solid #00A6FF;
      }
      .form__label {
        color: #00A6FF;
      }
      .form__checkbox {
        border: 1px solid #00A6FF!important;
      }
    }

    &.error {
      .form__input {
        border: 1px solid #D60067;
      }
      .form__label {
        color: #D60067;
      }
      .form__checkbox {
        border: 1px solid #D60067!important;
      }
    }
  }

  &-submit {
    margin-top: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 720.98px) {
      flex-direction: column;
      gap: 20px;
    }

    &__check {
      margin-left: 25px;
      display: flex;
      align-items: center;

      &.success {
        .form__checkbox {
          border: 1px solid #00A6FF!important;
        }
      }

      &.error {
        .form__checkbox {
          border: 1px solid #D60067!important;
        }
      }
    }

    &__text {
      display: block;
      margin-left: 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: #181818;

      a {
        color: #181818;
        text-decoration: underline;
      }
    }
  }

  &__label {
    position: absolute;
    top: -24px;
    left: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-main);
  }

  &__btn {
    padding: 18.5px 35px;
    background: #00A6FF;
    color: #fff;
    transition: background .3s ease-in-out;

    &:hover {
      background: #0088D1;
    }

    @media (max-width: 720.98px) {
      max-width: 290px;
      width: 100%;
      order: 2;
    }
  }

  &-message {
    padding: 10px 0;
    text-align: center;
    font-size: 20px;
  }
}
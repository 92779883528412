.form__input {
  width: 100%;
  height: 48px;
  padding: 14px;
  background: #fff;
  border-radius: 5px;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    border: 1px solid #00A6FF4D;
  }

  &:focus {
    border: 1px solid #102570;
  }
}

.form__textarea {
  height: 92px;
  resize: none;
}

.form__checkbox {
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid var(--color-light-blue);
  cursor: pointer;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.944336 6.88905L5.111 9.66683L12.7499 1.3335' stroke='%23102570' stroke-width='2.01011'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
}
.contacts {
  margin-top: 70px;

  @media (max-width: 720.98px) {
    margin-top: 40px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &-wrapper {
    display: flex;
    gap: 32px;

    @media (max-width: 1250.98px) {
      justify-content: space-between;
    }

    @media (max-width: 960.98px) {
      flex-direction: column;
    }
  }

  &-left {
    max-width: 490px;
    width: 100%;
    background: #EEF9FF;
    border-radius: 15px;

    @media (max-width: 1250.98px)  {
      max-width: 400px;
    }

    @media (max-width: 960.98px) {
      max-width: none;
    }
  }

  &-info {
    max-width: 360px;
    padding: 40px 0 0 40px;

    @media (max-width: 960.98px) {
      padding: 30px;
    }

    &__logo {
      margin-bottom: 40px;
    }

    &__address {
      margin-bottom: 19px;

      a {
        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
        color: var(--color-main);

        @media (max-width: 480.98px) {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }

    &__requisites {
      margin-bottom: 21px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        display: block;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        color: #393939;
      }
    }

    &__link {
      display: flex;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: var(--color-main);

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        display: block;
        margin-left: 8px;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #181818;
      }

      span {
        display: block;
        margin-left: 8px;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #181818;
      }
    }
  }

  &-map {
    iframe {
      border-radius: 15px;

      @media (max-width: 1250.98px)  {
        width: 480px;
      }

      @media (max-width: 960.98px) {
        width: 100%;
      }
    }
  }
}
.hero {
  margin-bottom: 70px;

  @media (max-width: 720.98px) {
    margin-bottom: 40px;
  }
  
  &-wrapper {
    padding: 27px 60px;
    display: flex;
    justify-content: space-between;
    background: #EEF9FF;
    border-radius: 15px;

    @media (max-width: 1250.98px) {
      gap: 20px;
    }

    @media (max-width: 960.98px) {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    @media (max-width: 720.98px) {
      gap: 37px;
    }

    @media (max-width: 480.98px) {
      padding: 30px 20px;
    }
  }

  &-info {
    max-width: 630px;

    @media (max-width: 1250.98px) {
      max-width: 400px;
    }

    @media (max-width: 960.98px) {
      max-width: none;
      text-align: center;
    }

    &__title {
      margin-bottom: 15px;
      font-size: 48px;
      font-weight: 500;
      line-height: 53px;
      color: var(--color-main);

      @media (max-width: 720.98px) {
        font-size: 28px;
        line-height: 30px;
      }
    }

    &__descr {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #181818;

      @media (max-width: 720.98px) {
        font-size: 15px;
        line-height: 18px;
      }
    }

    &__btn {
      position: relative;
      max-width: 260px;
      padding-right: 40px;
      transition: background .3s ease-in-out, color .3s ease-in-out;

      @media (max-width: 960.98px) {
        margin: 0 auto;
      }

      &::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 20px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289Z' fill='%23102570'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V8H7C6.44772 8 6 7.55228 6 7Z' fill='%23102570'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        transition: background-image .3s ease-in-out;
      }

      &:hover {
        background: var(--color-light-blue);
        color: #fff;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V8H7C6.44772 8 6 7.55228 6 7Z' fill='white'/%3E%3C/svg%3E ");
        }
      }
    }
  }
}